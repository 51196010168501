import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"

const About = ({ intl }) => (
  <Layout>
    <SEO title="About"/>
    <div className="hero-image">
      <p className="hero-image__text">{intl.formatMessage({ id: "nav.about" })}</p>
      <StaticImage className="hero-image__image hero-image__image--dark" src="../images/shop/inside.jpg" alt={intl.formatMessage({ id: "home.hero2.image" })}/>
    </div>
    <div className="flex-center">
      <div className="text-container">
        <div className="flex-center">
          <div>
            <h4 className="big-title">{intl.formatMessage({ id: "about.title1-left" })}</h4>
            <h4 className="big-title big-title--right">{intl.formatMessage({ id: "about.title1-right" })}</h4>
          </div>
        </div>
        <p className="text text--spaced">{intl.formatMessage({ id: "about.paragraph1" })}</p>
        <StaticImage className="about-image" objectPosition="50% 0%" src="../images/shop/owner.jpg" alt={intl.formatMessage({ id: "about.image" })}/>
      </div>
    </div>
  </Layout>
)

export default injectIntl(About)
